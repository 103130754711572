import React from 'react'

function Header() {
    return (
        <div className="Header">
            <h1>Welcome to react chat app</h1>
            <p>This is the beginning of this chat</p>
        </div>
    )
}

export default Header
